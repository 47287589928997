import request from '@/utils/request'

// 获取微信模板列表
export function getWXTemplateList (params) {
    return request({
        url: '/small-inteface/user-service/sysBanner/list',
        method: 'get',
        params
    })
}

// 根据id获取微信模板详情
export function getWXTemplateDetail (id) {
    return request({
        url:  `/small-inteface/user-service/sysBanner/${id}`,
        method: 'get',
    })
}

// 根据id删除微信模板
export function WXTemplateDelete (id) {
    return request({
        url: `/small-inteface/user-service/sysBanner/${id}`,
        method: 'delete'
    })
}


// 提交新增、编辑微信模板信息
export function subWXTemplateForm (method, data) {
    return request({
        url: '/small-inteface/user-service/sysBanner',
        method,
        data
    })
}

//接口：获取订阅消息模板列表
//地址：http://${BASE_URL}/small-inteface/wxma/message/queryTemplate?status=#{status}
export function queryWxSubscribeTemplate (data) {
    return request({
        url: `/small-inteface/wxma/message/queryTemplate?status=${data}`,
        method: 'get',
    })
}

//接口：启用 或者 失效模板
//地址：http://${BASE_URL}/small-inteface//wxma/message//operationTemplate?priTmplId#{priTmplId}&status=#{status}
export function switchWxTemplateStatus (data) {
    return request({
        url: `/small-inteface/wxma/message/operationTemplate?priTmplId=${data.priTmplId}&status=${data.status}`,
        method: 'post',
    })
}