<template>
  <div>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-select
            placeholder="选择状态"
            class="search-input ml-15"
            clearable
            v-model="searchObj.status"
          >
            <el-option
              v-for="(item, index) in member_status"
              :key="index"
              :label="item.status"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="getDataList"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
        <div class="pad-container">
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="模板类型" width="200">
              <template slot-scope="scope">
                {{
                  scope.row.type == 2
                    ? "一次性订阅"
                    : scope.row.type == 3
                    ? "长期订阅"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="模板标题"
              prop="title"
              width="200"
            ></el-table-column>
            <el-table-column label="模板内容" min-width="240" prop="content"></el-table-column>
            <el-table-column label="模板ID" min-width="180" prop="priTmplId"></el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <!-- {{scope.row.status == 1 ? '有效' : scope.row.status == 0 ?  '无效' : ''}} -->
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  @change="switchChangeStatus(scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  queryWxSubscribeTemplate,
  switchWxTemplateStatus,
} from "@/api/config_center/WeChatTemplateMessage_config";
export default {
  data() {
    return {
      id: "",
      tableData: [],
      member_status: [
        {
          status: "有效",
          value: 1,
        },
        {
          status: "无效",
          value: 0,
        },
      ],
      searchObj: {
        status: "",
      },
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    // 获取列表数据
    getDataList() {
      queryWxSubscribeTemplate(this.searchObj.status).then((res) => {
        // console.log(res.data.body);
        this.tableData = res.data.body;
      });
    },
    reset() {
      this.searchObj.status = "";
      this.getDataList();
    },
    switchChangeStatus(row) {
      // console.log(row);
      let params = {
        priTmplId: row.priTmplId,
        status: row.status,
      };
      switchWxTemplateStatus(params).then((res) => {
        // console.log(res.data);
        this.$message({
          type: "success",
          message: "操作成功",
        });

      });
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
</style>
